{
  "name": "my.mojo.ui",
  "version": "1.0.0",
  "scripts": {
    "start": "npm run dev",
    "start:ci": "cross-env NODE_ENV=development parcel serve ./src/index.html --no-cache &",
    "dev": "cross-env NODE_ENV=development parcel serve ./src/index.html",
    "dev-no-cache": "cross-env NODE_ENV=development parcel serve ./src/index.html --no-cache",
    "local-no-cache": "cross-env NODE_ENV=local parcel serve ./src/index.html --no-cache",
    "build-test": "cross-env NODE_ENV=test-env parcel build ./src/index.html",
    "build-prod": "cross-env NODE_ENV=production parcel build ./src/index.html",
    "test": "jest",
    "test:dev": "jest --watch",
    "eslint:check": "eslint \"**/*.{ts,tsx,js,jsx}\"",
    "eslint:fix": "eslint \"**/*.{ts,tsx,js,jsx}\" --fix",
    "prepare": "husky install",
    "prettier:check": "prettier --check \"./**/*.{ts,tsx}\" --ignore-path .gitignore",
    "prettier:fix": "prettier --write \"./**/*.{js,jsx,ts,tsx}\" --ignore-path .gitignore",
    "storybook": "start-storybook -p 6006",
    "build-storybook": "build-storybook",
    "knip": "knip"
  },
  "dependencies": {
    "@fortawesome/fontawesome-svg-core": "^1.2.35",
    "@fortawesome/free-solid-svg-icons": "^5.15.3",
    "@fortawesome/react-fontawesome": "^0.1.14",
    "@juggle/resize-observer": "^3.4.0",
    "@radix-ui/react-popover": "^1.0.2",
    "@react-spring/web": "^9.6.1",
    "@sentry/browser": "^7.1.1",
    "@sentry/tracing": "^7.1.1",
    "axios": "^0.21.1",
    "clean-deep": "^3.4.0",
    "dayjs": "^1.10.4",
    "deepmerge": "^4.2.2",
    "email-validator": "^2.0.4",
    "fast-clone": "^1.5.13",
    "filestack-react": "4.0.0",
    "husky": "^8.0.1",
    "js-cookie": "^2.2.1",
    "jwt-decode": "^3.1.2",
    "keyboardjs": "^2.6.4",
    "lint-staged": "^13.0.3",
    "mojo-storybook-v2-library": "git@github.com:mojomortgages/mojo.storybook.v2.library.git",
    "on-screen-keyboard-detector": "^2.3.0",
    "polished": "^4.1.2",
    "pure-react-carousel": "^1.27.8",
    "react": "^16.0.0",
    "react-device-detect": "^2.1.2",
    "react-dom": "^16.14.0",
    "react-error-boundary": "^3.1.3",
    "react-ga4": "^2.1.0",
    "react-helmet": "^6.1.0",
    "react-hooks-visible": "^1.1.1",
    "react-hot-toast": "^2.4.0",
    "react-is": "^17.0.2",
    "react-number-format": "4.4.1",
    "react-qr-code": "^2.0.7",
    "react-router-dom": "^5.2.0",
    "react-use-measure": "^2.1.1",
    "recoil": "^0.2.0",
    "smartlook-client": "^6.0.0",
    "smooth-scroll-into-view-if-needed": "^1.1.32",
    "styled-components": "^5.2.3",
    "styled-normalize": "^8.0.7",
    "swr": "^2.0.0",
    "uuid": "^9.0.0"
  },
  "devDependencies": {
    "@babel/preset-env": "^7.16.11",
    "@babel/preset-react": "^7.16.7",
    "@babel/preset-typescript": "^7.16.7",
    "@parcel/transformer-babel": "^2.8.3",
    "@parcel/transformer-image": "^2.0.0",
    "@parcel/transformer-svg-react": "^2.5.0",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^12.1.5",
    "@testing-library/react-hooks": "^8.0.1",
    "@testing-library/user-event": "^14.4.3",
    "@types/deepmerge": "^2.2.0",
    "@types/intercom-web": "^2.8.13",
    "@types/jest": "^29.2.0",
    "@types/keyboardjs": "^2.5.0",
    "@types/mixpanel-browser": "^2.35.7",
    "@types/node": "^20.14.11",
    "@types/react-router-dom": "^5.1.7",
    "@types/styled-components": "^5.1.9",
    "@typescript-eslint/eslint-plugin": "^5.52.0",
    "@typescript-eslint/parser": "^5.46.0",
    "babel-loader": "^8.2.5",
    "babel-plugin-styled-components": "^2.0.7",
    "buffer": "^5.7.1",
    "cross-env": "^7.0.3",
    "eslint": "^8.26.0",
    "jest": "^29.2.1",
    "jest-environment-jsdom": "^29.2.1",
    "jest-transform-stub": "^2.0.0",
    "knip": "^5.26.0",
    "parcel": "^2.5.0",
    "prettier": "^2.8.4",
    "react-test-renderer": "^16.0.0",
    "svgo": "2.5.0",
    "tsconfig-paths-webpack-plugin": "^4.0.0",
    "typescript": "^5.5.3",
    "wait-on": "^6.0.1"
  },
  "alias": {
    "state": "./src/State.ts",
    "logging": "./src/Logging.tsx",
    "hooks": "./src/hooks",
    "lib": "./src/lib",
    "models": "./src/models",
    "components": "./src/components",
    "styles": "./src/styles",
    "reducers": "./src/reducers",
    "templates": "./src/templates",
    "pages": "./src/pages",
    "assets": "./src/assets",
    "experiments": "./src/experiments",
    "contexts": "./src/contexts"
  },
  "browserslist": [
    "last 4 versions"
  ],
  "lint-staged": {
    "*.{ts,tsx,js,jsx}": [
      "eslint --fix",
      "prettier --write"
    ]
  },
  "overrides": {
    "react-refresh": "0.9.0"
  },
  "prettier": {
    "endOfLine": "lf",
    "semi": false,
    "printWidth": 100
  },
  "eslintConfig": {
    "ignorePatterns": [
      "dist/",
      "**/babel.config.js"
    ],
    "env": {
      "browser": true,
      "es2021": true
    },
    "extends": [
      "eslint:recommended",
      "plugin:@typescript-eslint/recommended"
    ],
    "overrides": [],
    "parser": "@typescript-eslint/parser",
    "parserOptions": {
      "ecmaFeatures": {
        "jsx": true
      },
      "ecmaVersion": "latest",
      "sourceType": "module"
    },
    "plugins": [
      "@typescript-eslint"
    ],
    "rules": {
      "no-empty-pattern": "warn",
      "no-unsafe-optional-chaining": "warn",
      "@typescript-eslint/ban-types": "warn",
      "@typescript-eslint/ban-ts-comment": "warn",
      "@typescript-eslint/no-empty-function": "warn",
      "@typescript-eslint/no-empty-interface": "warn",
      "@typescript-eslint/no-unused-vars": [
        "warn",
        {
          "argsIgnorePattern": "^_.*",
          "varsIgnorePattern": "^_.*"
        }
      ]
    }
  }
}
